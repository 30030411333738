angular.module('llax')
    .controller('AssetFoldersController', function ($dialogs, $filter, $http, $log, $modal, $modalInstance, $rootScope, $scope, $timeout, $q,
        $window, AssetFoldersResource, AssetFoldersService, growl, modalParams) {

        $scope.LINK_TYPE_FOLDER = AssetFoldersService.LINK_TYPE_FOLDER;
        $scope.LINK_TYPE_ASSET = AssetFoldersService.LINK_TYPE_ASSET;

        var LOCAL_STORAGE_MODE_KEY = 'assetFolders.viewMode';
        $scope.VIEW_MODE_GRID = 'GRID';
        $scope.VIEW_MODE_LIST = 'LIST';
        $scope.VIEW_MODE_LARGE_THUMBNAILS = 'LARGE_THUMBNAILS';

        $scope.currentPath = "";
        $scope.currentPathComponents = [];
        $scope.currentFolder = null;
        $scope.copiedItem = null;
        $scope.copiedMultiSelectedItems = [];
        $scope.selectedItem = null;
        $scope.items = [];
        $scope.filteredItems = [];
        $scope.vm = { query: null };
        $scope.loadingSelectedItemInfo = false;
        $scope.loading = true;
        $scope.initializing = true;
        $scope.forwardPaths = [];
        $scope.multiSelectedItems = [];

        $scope.customDamMode = false; // Used to track if we have custom DAM widgets enabled

        $scope.refresh = refresh;
        $scope.uploader = getUploader($scope.currentPath, $scope.refresh);
        $scope.decodeFilename = AssetFoldersService.decodeFilename;

        if ($window.localStorage.getItem(LOCAL_STORAGE_MODE_KEY)) {
            $scope.currentViewMode = $window.localStorage.getItem(LOCAL_STORAGE_MODE_KEY);
        } else {
            $scope.currentViewMode = $scope.VIEW_MODE_GRID;
        }

        if (!_.isNil($modalInstance)) {
            // We are in a modal
            $scope.isModal = true;
        }

        $scope.init = function() {

            $scope.initializing = true;
            var customAssetFoldersWidgetService = $rootScope.getService('CustomAssetFoldersWidgetService');

            if (_.isNil(customAssetFoldersWidgetService)) {
                $scope.initNativeAssetFolders();
                return;
            }

            if (_.isNil(customAssetFoldersWidgetService.isWidgetEnabled) ||
                _.isNil(customAssetFoldersWidgetService.initializePageWidgetAsync) ||
                _.isNil(customAssetFoldersWidgetService.initializeBrowseWidgetAsync)) {
                $log.error("Misconfigured `CustomAssetFoldersWidgetService`, falling back to the native asset folders management.");
                $scope.initNativeAssetFolders();
                return;
            }

            var isWidgetEnabled = customAssetFoldersWidgetService.isWidgetEnabled($rootScope.user, $rootScope.organization);
            if (!isWidgetEnabled) {
                $scope.initNativeAssetFolders();
                return;
            }

            $scope.customDamMode = true;
            var domContainerId = '#dam-container';
            var onSelectedCallback = $scope.isModal ? modalParams.onFileSelected : _.noop;
            var onCloseCallback = $scope.cancel;

            if ($scope.isModal) {
                // We are in a modal/dialog, let's initialize the browse widget.
                customAssetFoldersWidgetService.initializeBrowseWidgetAsync(domContainerId, onSelectedCallback, onCloseCallback)
                    .then(function() {
                        $scope.initializing = false;
                    })
                    .catch(function(error) {
                        $scope.customDamMode = false;

                        $log.error("There was an error initializing browse widget, falling back to the native asset folders management", error);
                        $scope.initNativeAssetFolders();
                    });
            } else {
                // We are in a full page mode, let's initialize the page widget.
                customAssetFoldersWidgetService.initializePageWidgetAsync(domContainerId, onSelectedCallback, onCloseCallback)
                    .then(function() {
                        $scope.initializing = false;
                    })
                    .catch(function(error) {
                        $scope.customDamMode = false;

                        $log.error("There was an error initializing page widget, falling back to the native asset folders management", error);
                        $scope.initNativeAssetFolders();
                    });
            }

        };

        $scope.initNativeAssetFolders = function() {
            AssetFoldersResource().getRootFolderContents(function (response) {
                $scope.currentPath = "";
                $scope.currentPathComponents = [];
                $scope.currentFolder = null;
                $scope.selectedItem = null;
                $scope.multiSelectedItems = [];

                $scope.items = AssetFoldersService.sortAssetItems(response);
                $scope.setFilteredItems($scope.items, $scope.vm.query);
                $scope.uploader = getUploader($scope.currentPath, $scope.refresh);
                $scope.loading = false;
                $scope.initializing = false;
            });
        };

        $scope.getFolderContentsByPath = function(path) {
            if (!_.isEmpty(path) && path[0] === "/") {
                path = path.slice(1, path.length);
            }

            $scope.loading = true;

            AssetFoldersResource(path).getFolderContents(function (response) {
                $scope.currentPath = path;
                resetCurrentPathComponents(path);

                $scope.items = AssetFoldersService.sortAssetItems(response);
                $scope.setFilteredItems($scope.items);
                $scope.selectedItem = null;
                $scope.uploader = getUploader($scope.currentPath, $scope.refresh);
                $scope.loading = false;
                $scope.multiSelectedItems = [];
            });
        };

        $scope.getFolderContents = function(path, folderName) {
            var fullPath = '';
            if (!_.isEmpty(path)) {
                fullPath = path + "/" + folderName;
            } else {
                fullPath = folderName;
            }
            $scope.getFolderContentsByPath(fullPath);
        };

        function refresh() {
            if (_.isEmpty($scope.currentPath)) {
                $scope.init();
            } else {
                $scope.getFolderContentsByPath($scope.currentPath);
            }
        }

        $scope.handleItemDoubleClick = function(item) {
            if (item.linkType === $scope.LINK_TYPE_ASSET && $scope.isImageFile(item)) {
                $scope.showImageFile($scope.getImageUrl(item));
            } else if (item.linkType === $scope.LINK_TYPE_FOLDER){
                $scope.forwardPaths = [];
                $scope.getFolderContents($scope.currentPath, item.name);
            }
        };

        $scope.handleBreadcrumbClick = function(pathComponent) {
            if (_.isEmpty(pathComponent)) {
                $scope.init();
            } else {
                var path = "";
                for (var i = 0; i < $scope.currentPathComponents.length; i++) {
                    path += "/" + $scope.currentPathComponents[i];
                    if ($scope.currentPathComponents[i] === pathComponent) {
                        break;
                    }
                }
                $scope.getFolderContentsByPath(path);
            }
        };

        $scope.handleBackClick = function() {
            $scope.forwardPaths.push($scope.currentPath);
            var path = "";
            for (var i = 0; i < $scope.currentPathComponents.length - 1; i++) {
                path += "/" + $scope.currentPathComponents[i];
            }

            if (_.isEmpty(path)) {
                $scope.init();
            } else {
                $scope.getFolderContentsByPath(path);
            }
        };

        $scope.handleForwardClick = function() {
            if ($scope.forwardPaths.length !== 0) {
                var previousPath = $scope.forwardPaths.pop();
                $scope.getFolderContentsByPath(previousPath);
            }
        };

        $scope.selectItem = function(item) {
            $timeout(function() {
                $scope.selectedItem = item;
                $scope.loadingSelectedItemInfo = false;
            }, 0);
        };

        $scope.setFilteredItems = function(items, query) {
            if (!_.isEmpty(query)) {
                $scope.filteredItems = _.filter(items, function(item) {
                    return item.name.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) != -1;
                });
            } else {
                $scope.filteredItems = items;
            }
        };

        $scope.showImageFile = function(url) {
            $modal.open({
                templateUrl: 'tpl/editorShowImage.tpl.html',
                controller: 'showImageController',
                windowClass: 'editor-show-image',
                backdrop: true,
                resolve: {
                    filePreviewData: function () {
                        return {
                            isImage: true,
                            url: url
                        };
                    }
                }
            });
        };

        $scope.showCreateModal = function() {
            $modal.open({
                templateUrl: 'tpl/asset-folders/modal-create.tpl.html',
                controller: 'AssetFoldersModalCreateFolderController',
                backdrop: true,
                resolve: {
                    data: function () {
                        return {
                            path: $scope.currentPath,
                            onDone: $scope.onCreateDone
                        };
                    }
                }
            });
        };

        $scope.showUploadModal = function() {
            $modal.open({
                templateUrl: 'tpl/asset-folders/modal-upload.tpl.html',
                controller: 'AssetFoldersModalUploadController',
                backdrop: true,
                resolve: {
                    data: function () {
                        return {
                            path: $scope.currentPath,
                            onCancel: function() {
                                $scope.broadcastChildsUpdated($scope.currentPath);
                                $scope.refresh();
                            }
                        };
                    }
                }
            });
        };

        $scope.showDeleteModal = function() {
            var confirmDialog = $dialogs.confirm('CONFIRM_DELETE');

            if ($scope.multiSelectedItems.length === 0) {
                // Delete single asset/folder item
                var itemPath = $scope.currentPath ? $scope.currentPath + "/" + $scope.selectedItem.name : $scope.selectedItem.name;
                confirmDialog.result.then(function(){
                    $scope.deleting = true;
                    AssetFoldersResource(itemPath).delete(function (response) {
                        growl.success('ASSET_FOLDER.DELETED_SUCCESSFULLY');
                        $scope.deleting = false;
                        $scope.refresh();
                        $scope.broadcastChildsUpdated($scope.currentPath);
                    }, function (error) {
                        $scope.deleting = false;

                        if (error.data.message) {
                            growl.error(error.data.message);
                        } else {
                            growl.error('ASSET_FOLDER.ERROR_OCCURRED');
                        }
                    });
                });
            } else {
                // Delete multiple assets/folders.
                var itemPaths = $scope.multiSelectedItems.map(function(item) {
                    return $scope.currentPath ? $scope.currentPath + "/" + item.name : item.name;
                });
                confirmDialog.result.then(function(){
                    $scope.deleting = true;
                    // FIXME: Solved in angular +1.6.4, as they interpret the specs such that the DELETE request has no body in prior versions.
                    $http({
                        method: 'DELETE',
                        url: lax_rest_url('assetFolders') + '/_deleteItems',
                        data: itemPaths,
                        headers: {'Content-Type': 'application/json;charset=utf-8'}
                    }).then(function(response) {
                        growl.success('ASSET_FOLDER.DELETED_SUCCESSFULLY');
                        $scope.deleting = false;
                        $scope.refresh();
                        $scope.broadcastChildsUpdated($scope.currentPath);
                    }, function(error) {
                        $scope.deleting = false;

                        if (error.data.message) {
                            growl.error(error.data.message);
                        } else {
                            growl.error('ASSET_FOLDER.ERROR_OCCURRED');
                        }
                    });
                });
            }
        };

        $scope.showRenameModal = function() {
            $modal.open({
                templateUrl: 'tpl/asset-folders/modal-rename.tpl.html',
                controller: 'AssetFoldersRenameFolderController',
                backdrop: true,
                resolve: {
                    data: function () {
                        return {
                            path: $scope.currentPath,
                            name: $scope.decodeFilename($scope.selectedItem.name),
                            linkType: $scope.selectedItem.linkType,
                            onDone: $scope.onRenameDone
                        };
                    }
                }
            });
        };

        $scope.getImageUrl = function(item) {
            return item.privateAssetUrl;
        };

        $scope.getFileIcon = function(fileName) {
            return AssetFoldersService.getFileIcon(fileName);
        };

        $scope.isImageFile = function(assetFolder) {
            if (_.isNil(assetFolder) || _.isNil(assetFolder.contentType)) {
                return false;
            }

            return $filter('isFileType')({ type: assetFolder.contentType }, 'drawable');
        };

        $scope.download = function() {
            if ($scope.multiSelectedItems.length > 0) {
                // Download multiple files
                var downloadableUrls = _.reduce($scope.multiSelectedItems, function(result, item) {
                    if (item.linkType === $scope.LINK_TYPE_ASSET) {
                        var url = $scope.getImageUrl(item);
                        result.push(url);
                    }

                    return result;
                }, []);

                if (_.isEmpty(downloadableUrls)) {
                    return;
                }

                _.forEach(downloadableUrls, function(url) {
                    $scope.downloadFile(url);
                });
            } else {
                // Download single file
                $scope.downloadFile($scope.getImageUrl($scope.selectedItem));
            }
        };

        $scope.downloadFile = function(url) {
            $timeout(function() {
                var anchor = document.createElement('a');
                anchor.setAttribute('href', url);
                anchor.setAttribute('hidden', true);
                anchor.setAttribute('download', '');
                anchor.setAttribute('target', '_blank');
                document.body.appendChild(anchor);
                anchor.click();
                anchor.remove();
            }, 0);
        };

        $scope.downloadButtonClick = function() {
            $timeout(function() {
                $('#button-download')[0].click();
            }, 0);
        };

        $scope.cancel = function() {
            $modalInstance.close();
        };

        $scope.selectFile = function(currentPath, item) {

            var assetPath = "";
            if (!_.isEmpty(currentPath)) {
                assetPath = currentPath + "/" + item.name;
            } else {
                assetPath = item.name;
            }

            modalParams.onFileSelected(item, assetPath);
            $scope.cancel();
        };

        $scope.isFileTypeAccepted = function(item) {
            if(!_.isNil(modalParams) && !_.isEmpty(modalParams.accept)) {
                if (_.includes(modalParams.accept, item.contentType)) {
                    return true;
                } else {
                    return false;
                }
            }

            return true;
        };

        $scope.setActiveViewMode = function(viewMode) {
            $window.localStorage.setItem(LOCAL_STORAGE_MODE_KEY, viewMode);
            $scope.currentViewMode = viewMode;
        };

        $scope.broadcastChildsUpdated = function(path) {
            $scope.$broadcast(AssetFoldersService.EVENT_CHILDS_UPDATED, {
                path: path
            });
        };

        $scope.broadcastUpdated = function(path, newName) {
            $scope.$broadcast(AssetFoldersService.EVENT_UPDATED, {
                path: path,
                newName: newName
            });
        };

        $scope.onRenameDone = function(path, newName) {
            $scope.broadcastUpdated(path, newName);
            $scope.refresh();
        };

        $scope.onCreateDone = function() {
            $scope.broadcastChildsUpdated($scope.currentPath);
            $scope.refresh();
        };

        $scope.onSearchQueryChange = function() {
            $scope.setFilteredItems($scope.items, $scope.vm.query);
        };

        $scope.onItemDragStart = function($event, draggedItem) {

            // Note that: this will be called on the DRAGGED item.
            var draggedItems = [];
            if (!_.isEmpty($scope.multiSelectedItems)) {
                draggedItems = $scope.multiSelectedItems;
            } else {
                draggedItems.push(draggedItem);
            }

            AssetFoldersService.prepareDragAndDropEvent($event, draggedItems, $scope.currentPath);
        };

        $scope.onItemDragFinished = function(droppedItems, droppedOnItem) {

            // Ignore drops on asset files
            if (!_.isNil(droppedOnItem) && droppedOnItem.linkType === $scope.LINK_TYPE_ASSET) {
                return;
            }

            AssetFoldersService.handleDropEventAsync(droppedItems, droppedOnItem, $scope.currentPath, $scope.onItemMovedSuccessfully)
                .then(function(){
                    $scope.multiSelectedItems = [];
                })
                .catch(function(error) {
                    $scope.onItemMovedError(error);
                });
        };

        $scope.cutItem = function() {

            if (!_.isEmpty($scope.multiSelectedItems)) {
                // Cut multiple items
                $scope.copiedMultiSelectedItems = [];
                $scope.copiedItem = null;

                _.forEach($scope.multiSelectedItems, function(item) {
                    var itemFullPath =
                        AssetFoldersService.prependCurrentPath($scope.currentPath, item.name);

                    var copiedItem = {
                        fullPath: itemFullPath,
                        item: item
                    };

                    $scope.copiedMultiSelectedItems.push(copiedItem);
                });
            } else if(!_.isNil($scope.selectedItem)) {
                // Cut single item
                $scope.copiedMultiSelectedItems = [];
                $scope.copiedItem = null;

                var itemFullPath = AssetFoldersService.prependCurrentPath($scope.currentPath, $scope.selectedItem.name);

                $scope.copiedItem = {
                    fullPath: itemFullPath,
                    item: $scope.selectedItem
                };
            }

        };

        $scope.handlePasteItemClick = function(selectedItem) {

            if(!$scope.isPasteActionEnabled()) {
                return;
            }

            if (!_.isEmpty($scope.copiedMultiSelectedItems)) {
                var copiedItems = $scope.copiedMultiSelectedItems;
                $scope.pasteItems(copiedItems, selectedItem);
            } else {
                var copiedItem = $scope.copiedItem;
                $scope.pasteItem(copiedItem, selectedItem);
            }
        };

        $scope.pasteItem = function(sourceItem, destinationItem) {

            var sourcePath = sourceItem.fullPath;
            var itemType = sourceItem.item.linkType;

            var destinationPath = '';
            if (_.isNil(destinationItem)) {
                destinationPath = $scope.currentPath;
            } else {
                destinationPath = AssetFoldersService.prependCurrentPath($scope.currentPath, destinationItem.name);
            }

            AssetFoldersService.moveItemAsync(itemType, sourcePath, destinationPath)
                .then(function() {
                    $scope.onItemMovedSuccessfully(sourcePath, destinationPath);
                    $scope.copiedItem = null;
                }).catch(function(error){
                    $scope.onItemMovedError(error);
                });
        };

        $scope.pasteItems = function(sourceItems, destinationItem) {

            var destinationPath = '';
            if (_.isNil(destinationItem)) {
                destinationPath = $scope.currentPath;
            } else {
                destinationPath = AssetFoldersService.prependCurrentPath($scope.currentPath, destinationItem.name);
            }

            var moveRequests = [];
            _.forEach(sourceItems, function(sourceItem) {
                var sourcePath = sourceItem.fullPath;
                var itemType = sourceItem.item.linkType;

                var request = AssetFoldersService.moveItemAsync(itemType, sourcePath, destinationPath)
                    .then(function() {
                        $scope.onItemMovedSuccessfully(sourcePath, destinationPath);
                    });
                moveRequests.push(request);
            });

            $q.all(moveRequests)
                .then(function() {
                    $scope.copiedMultiSelectedItems = [];
                }).catch(function(error){
                    $scope.onItemMovedError(error);
                });
        };

        $scope.isPasteActionEnabled = function() {
            return !_.isEmpty($scope.copiedMultiSelectedItems) || !_.isNil($scope.copiedItem);
        };

        $scope.onItemMovedSuccessfully = function(sourcePath, destinationPath) {
            var sourceParentFolderPath = AssetFoldersService.getParentFolderPath(sourcePath);
            $scope.refresh();
            $scope.broadcastChildsUpdated(sourceParentFolderPath);
            $scope.broadcastChildsUpdated(destinationPath);
            growl.success('ASSET_FOLDER.ASSET_ITEM_MOVED_SUCCESSFULLY');
        };

        $scope.onAllItemsMovedSuccessfully = function(path) {
            if (_.isEmpty(path)) {
                $scope.init();
            } else {
                $scope.getFolderContentsByPath(path);
            }
        };

        $scope.onItemMovedError = function(error) {
            $log.error(error);
            var errorMessage = 'ASSET_FOLDER.ERROR_OCCURRED';
            if (!_.isNil(error.data) && !_.isNil(error.data.message)) {
                errorMessage = error.data.message;
            }
            growl.error(errorMessage);
        };

        $scope.onMultiItemSelected = function(item) {
            if (item.multiSelected) {
                $scope.multiSelectedItems.push(item);
            } else {
                _.remove($scope.multiSelectedItems, { name: item.name });
            }
            $scope.selectItem(item);
        };

        $scope.clearMultiSelection = function() {
            $scope.multiSelectedItems = [];
            $scope.selectedItem = null;
            _.forEach($scope.items, function(item) {
                item.multiSelected = false;
            });

            if ($scope.currentViewMode === $scope.VIEW_MODE_LIST) {
                $scope.$broadcast('notifyMultiSelectionChanged');
            }
        };

        $scope.multiSelectAll = function() {
            $scope.multiSelectedItems = [];
            _.forEach($scope.items, function(item) {
                item.multiSelected = true;
                $scope.multiSelectedItems.push(item);
            });

            if ($scope.currentViewMode === $scope.VIEW_MODE_LIST) {
                $scope.$broadcast('notifyMultiSelectionChanged');
            }
        };

        function getUploader(path, onComplete) {

            function onCompleteAll(uploaderScope) {
                var failed = false;
                for (var i = 0; i < uploaderScope.queue.length; i ++) {
                    var item = uploaderScope.queue[i];
                    if (item.isUploaded && item.isError) {
                        failed = true;
                    }
                }

                if (!failed) {
                    growl.success('ASSET_FOLDER.UPDATED_SUCCESSFULLY');
                    onComplete();
                }
                uploaderScope.queue = [];
            }

            function onErrorItem(response) {
                var error = 'ASSET_FOLDER.UPLOAD_ITEM_FAILED';
                if (response.message) {
                    error = response.message;
                }
                growl.error(error);
            }

            var encodedPath = encodeURIComponent(path);
            return AssetFoldersService.getUploader(encodedPath, {
                autoUpload: true,
                reset: true,
                multiple: true,
                useFilename: true,
                onCompleteAll: onCompleteAll,
                onErrorItem: onErrorItem
            });
        }

        function resetCurrentPathComponents(path) {
            $scope.currentPathComponents = _.isEmpty(path) ? [] : path.split("/");
        }
    });
